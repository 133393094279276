import React, { Fragment } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import { toMoney, getTextPlural } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import TotalAmount from 'src/UIComponents/KPIDetails/TotalAmount'
import BarChart from '../Charts/BarChart'
import PeriodLabel from '../Label/PeriodLabel'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { darkGray, normalText } from 'src/Styles/settings/Constants'

const KPITransfersSucceeded = ({ amount, count, color, dataChart, barChartData, isLoading, period }) => (
  <Fragment>
    {!isLoading && count === 0
      ? (<SidebarContainer emptyState>
        <EmptyState
          iconSrc='/assets/icons/alerts/success.svg'
          title={'Poxa! Nenhuma transferência foi aprovada no\nperíodo selecionado'}
        />
      </SidebarContainer>)
      : (<SidebarContainer>
        <Grid.Row auto>
          <DateContainer cols={16}>
            <PeriodLabel period={period} />
          </DateContainer>
        </Grid.Row>
        <Grid.Row auto>
          <TotalAmount
            amount={toMoney(amount)}
            count={`${count} ${getTextPlural('transfer', count)}`}
            isLoading={isLoading}
          />
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='transfer-kpi-distribuition'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Distribuição
            </Label>
            <BarChart layout={'horizontal'} color={color} height={252} data={dataChart} itemsType='transfer' isLoading={isLoading} />
          </Card>
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='transfer-kpi-types'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Tipos de Transferência
            </Label>
            <Grid.Row />
            <BarChart color={color} data={barChartData} itemsType='transfer' isLoading={isLoading} />
          </Card>
        </Grid.Row>
      </SidebarContainer>)
    }
  </Fragment>
)

KPITransfersSucceeded.propTypes = {
  amount: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  dataChart: PropTypes.array,
  isLoading: PropTypes.bool,
  barChartData: PropTypes.array,
  period: PropTypes.number
}

const DateContainer = styled(Grid.Col)`
  text-align: center;
  font-size: ${normalText};
`

export default KPITransfersSucceeded
