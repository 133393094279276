import React, { Fragment } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import { toMoney, getTextPlural } from 'src/Utils/Utils'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import TotalAmount from 'src/UIComponents/KPIDetails/TotalAmount'
import PieChart from 'src/UIComponents/Charts/PieChart'
import PeriodLabel from '../Label/PeriodLabel'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { darkGray, normalText } from 'src/Styles/settings/Constants'

const KPITransfersFailed = ({ period, amount, count, color, pieData, isLoading }) => (
  <Fragment>
    {!isLoading && count === 0
      ? (<SidebarContainer emptyState>
        <EmptyState
          iconSrc='/assets/icons/alerts/success.svg'
          title={'Que bom! Nenhuma transferência falhada no\nperíodo selecionado'}
        />
      </SidebarContainer>)
      : (<SidebarContainer>
        <Grid.Row auto>
          <DateContainer cols={16}>
            <PeriodLabel period={period} />
          </DateContainer>
        </Grid.Row>
        <Grid.Row auto>
          <TotalAmount
            amount={toMoney(amount)}
            count={`${count} ${getTextPlural('transfer', count)}`}
            isLoading={isLoading}
          />
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='transfer-kpi-failed'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Falhas por tipo de transferência
            </Label>
            <Grid.Row />
            <PieChart color={color} width='100%' height={320} data={pieData} itemsType='transfer' isLoading={isLoading} />
          </Card>
        </Grid.Row>
      </SidebarContainer>)
    }
  </Fragment>
)

KPITransfersFailed.propTypes = {
  period: PropTypes.number,
  amount: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  pieData: PropTypes.array,
  isLoading: PropTypes.bool
}

const DateContainer = styled(Grid.Col)`
  text-align: center;
  font-size: ${normalText};
`

export default KPITransfersFailed
