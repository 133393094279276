import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { ChevronDown } from 'src/UIComponents/Chevron/Chevron'
import { EnhancedUsersMenu as UsersMenu } from 'src/UIComponents/HeaderUser/UsersMenu'

import { lightGray, darkGray, darkerGray, smallerText, normalText, smallMargin } from 'src/Styles/settings/Constants'
import { SelectMarketplace } from 'src/Components/SelectMarketplace/SelectMarketplace'
import Button from 'src/UIComponents/Button/ButtonDefault'

const HeaderUserUI = ({ user, users, onUserSelect, logout, onMyProfileClick, handleClickConfig, enableConfig }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)

  return (
    <Container>
      <SelectMarketplace data-tracking='MktplaceFilho' />
      <LeftMenuContainer>
        <UserWrapper data-tracking='SwitchAccount' className='ignore-react-onclickoutside' onClick={() => setDisplayDropdown(!displayDropdown)}>
          <Text>
            Olá, <strong>{user.name}</strong>
          </Text>
          <Chevron />
          {displayDropdown &&
            <UsersMenu
              handleClose={() => setDisplayDropdown(false)}
              logout={logout}
              user={user}
              users={users}
              onUserSelect={onUserSelect}
              onMyProfileClick={onMyProfileClick}
            />
          }
        </UserWrapper>
        {enableConfig && <ButtonConfig data-test='config-button' inversed onClick={handleClickConfig} icon='/assets/icons/system/settings.svg' />}
      </LeftMenuContainer>
    </Container>
  )
}

HeaderUserUI.propTypes = {
  user: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
  onUserSelect: PropTypes.func,
  logout: PropTypes.func,
  onMyProfileClick: PropTypes.func,
  handleClickConfig: PropTypes.func,
  enableConfig: PropTypes.bool
}

const Container = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${lightGray};
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonConfig = styled(Button)`
  width: 45px;
  height: 30px;
  padding: 0 0 2px 0;
  margin-left: 10px;

  > i {
    margin: 0;
  }
`

const UserWrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`

const Text = styled.p`
  font-size: ${normalText};
  line-height: 20px;
  color: ${darkerGray};

  > strong {
    margin-right: ${smallMargin};
  }

  ${({ small }) => small && css`
    font-size: ${smallerText};

    > strong {
      font-size: ${normalText};
    }
  `}

  ${({ gray }) => gray && css`
    color: ${darkGray};
  `}
`

const Chevron = styled(ChevronDown)`
  margin-top: 3px;
`

export { HeaderUserUI }
