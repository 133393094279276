import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const LineCardLoader = ({ loadingForm, ...props }) => (
  <Wrapper
    width={1000}
    height={loadingForm ? 197 : 18}
    viewBox={loadingForm ? '0 0 1000 197' : '0 0 1000 18'}
    speed={4}
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    {loadingForm
      ? (
        <>
          <rect x='10' y='0' rx='5' ry='5' width='284' height='10' />
          <rect x='10' y='21' rx='5' ry='5' width='284' height='10' />
          <rect x='10' y='79' rx='5' ry='5' width='284' height='10' />
          <rect x='10' y='100' rx='5' ry='5' width='284' height='10' />
          <rect x='10' y='159' rx='5' ry='5' width='284' height='10' />
          <rect x='10' y='180' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='0' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='21' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='80' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='100' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='159' rx='5' ry='5' width='284' height='10' />
          <rect x='359' y='180' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='0' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='21' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='80' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='100' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='159' rx='5' ry='5' width='284' height='10' />
          <rect x='704' y='180' rx='5' ry='5' width='284' height='10' />
        </>
      )
      : (
        <>
          <rect x='24' y='0' rx='5' ry='5' width='960' height='18' />
        </>
      )}
  </Wrapper>
)

const Wrapper = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  padding: 30px 0;
`

export default LineCardLoader
