import React from 'react'
import ContentLoader from 'react-content-loader'

export const TransactionsByCardBrandLoader = () => (
  <ContentLoader
    speed={4}
    width={360}
    height={171}
    viewBox='0 0 360 171'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='236' y='21' rx='5' ry='5' width='124' height='16' />
    <rect x='276' y='41' rx='5' ry='5' width='84' height='10' />
    <rect x='4' y='32' rx='5' ry='5' width='21' height='21' />
    <rect x='45' y='32' rx='5' ry='5' width='40' height='20' />
    <rect x='236' y='81' rx='5' ry='5' width='124' height='16' />
    <rect x='276' y='101' rx='5' ry='5' width='84' height='10' />
    <rect x='4' y='92' rx='5' ry='5' width='21' height='21' />
    <rect x='45' y='92' rx='5' ry='5' width='40' height='20' />
    <rect x='236' y='139' rx='5' ry='5' width='124' height='16' />
    <rect x='276' y='159' rx='5' ry='5' width='84' height='10' />
    <rect x='4' y='150' rx='5' ry='5' width='21' height='21' />
    <rect x='45' y='150' rx='5' ry='5' width='40' height='20' />
    <rect x='0' y='124' rx='0' ry='0' width='360' height='1' />
    <rect x='0' y='67' rx='0' ry='0' width='360' height='1' />
  </ContentLoader>
)
