import React from 'react'
import ContentLoader from 'react-content-loader'

export const TransactionsBySellerLoader = () => (
  <ContentLoader
    speed={4}
    width={360}
    height={215}
    viewBox='0 0 360 215'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='0' y='21' rx='5' ry='5' width='80' height='14' />
    <rect x='0' y='41' rx='5' ry='5' width='180' height='14' />
    <rect x='0' y='101' rx='5' ry='5' width='80' height='14' />
    <rect x='0' y='121' rx='5' ry='5' width='180' height='14' />
    <rect x='0' y='179' rx='5' ry='5' width='80' height='14' />
    <rect x='0' y='199' rx='5' ry='5' width='180' height='14' />
    <rect x='0' y='157' rx='0' ry='0' width='360' height='1' />
    <rect x='0' y='77' rx='0' ry='0' width='360' height='1' />
  </ContentLoader>
)
