import styled, { css } from 'styled-components'

const InputCSS = css`
  padding: 0.375rem 0.75rem;
  outline: none;
  font-size: 1.2rem;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const Input = styled.input`
  ${InputCSS}
`

export default Input

export {
  InputCSS
}
