import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Grid from 'src/UIComponents/Grid/Grid'
import {
  lightGray,
  smallPadding,
  lighterGray
} from 'src/Styles/settings/Constants'

const List = ({ items, line, noRowPadding, isStriped }) => {
  return (
    <Grid noPadding>
      {items.map((item, index) => <Row isStriped={isStriped} noRowPadding={noRowPadding} line={line} key={index} noMargin auto>{item}</Row>)}
    </Grid>
  )
}

List.defaultProps = {
  items: []
}

List.propTypes = {
  items: PropTypes.array,
  line: PropTypes.bool,
  noRowPadding: PropTypes.bool,
  isStriped: PropTypes.bool
}

export default List

const Row = styled(Grid.Row)`
  padding: ${smallPadding} 0;

  ${({ line }) => line && css`
    border-bottom: 1px solid ${lightGray};

    :last-child {
      border: 0;
      padding-bottom: 0;
    }
  `}

  ${({ noRowPadding }) => noRowPadding && css`
    padding: 0;
  `}

  ${({ isStriped }) => isStriped && css`
    :nth-child(even) {
      background-color: ${lighterGray}
    }
  `}
`
