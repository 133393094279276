import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { textBold, smallText } from 'src/Styles/settings/Constants'

const Label = ({ children, color, bold, uppercase, fontSize, textAlign, overflow, underline, className, width, breakLine, background, ...props }) => {
  return (
    <LabelContainer
      {...props}
      className={className}
      color={color}
      bold={bold}
      uppercase={uppercase}
      fontSize={fontSize}
      textAlign={textAlign}
      overflowText={overflow}
      width={width}
      breakLine={breakLine}
      background={background}
      underline={underline}
    >
      {children}
    </LabelContainer>
  )
}

Label.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  breakLine: PropTypes.bool,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  overflow: PropTypes.bool,
  underline: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  background: PropTypes.string
}

const LabelContainer = styled.span`
  color: ${(props) => props.color || props.theme.color};
  font-size: ${smallText};

  ${({ underline }) => underline && css`
    text-decoration: underline;
  `}

  ${({ marginRight }) => marginRight && css`
    margin-right: ${marginRight}rem;
  `}

  ${({ marginLeft }) => marginLeft && css`
    margin-left: ${marginLeft}rem;
  `}

  ${({ textAlign }) => textAlign === 'center' && css`
    margin: 0 auto;
    text-align: center;
  `}

  ${({ textAlign }) => textAlign === 'right' && css`
    float: right;
    text-align: right;
  `}

  ${({ textAlign }) => textAlign === 'left' && css`
    float: left;
    text-align: left;
  `}

  ${({ bold }) => bold && css`
    font-weight: ${textBold};
  `}

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}

  ${({ width }) => width && css`
    max-width: ${width};
    width: ${width};
  `}

  ${({ overflowText }) => overflowText && css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  `}

  ${({ breakLine }) => breakLine && css`
    white-space: pre;
  `}
  ${({ background }) => background && css`
    background: ${background};
  `}
`

export default Label
