import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import styled, { css } from 'styled-components'

import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import Label from '../Label/Label'
import HeaderSellerInfoLoader from 'src/UIComponents/HeaderSellerDetail/HeaderSellerInfoLoader'
import HeaderLabelCardLoader from 'src/UIComponents/HeaderSellerDetail/HeaderLabelCardLoader'
import Card from 'src/UIComponents/Card/Card'

import {
  lighterGray,
  darkerGray,
  normalText,
  bigText,
  darkGray,
  smallMargin
} from 'src/Styles/settings/Constants'

const HeaderSellerDetail = ({ item, infoResume, isRequesting }) => {
  const name = item
    ? item.type === 'individual'
      ? `${item.first_name} ${item.last_name}`
      : `${item.business_name || ''}`
    : ''
  const fantasyName = item
    ? item.type === 'business' && item.statement_descriptor
    : ''
  const document = item
    ? item.type === 'individual'
      ? item.taxpayer_id.toCPF()
      : item.ein?.toCnpj()
    : ''
  const id = item ? item.id : ''

  return (
    <Wrapper>
      <Container>
        {isRequesting ? (
          <HeaderSellerInfoLoader />
        ) : (
          <>
            <LabelName
              uppercase
              overflow
              bold
              fontSize={bigText}
              color={darkerGray}
            >
              {name}
            </LabelName>
            {fantasyName && (
              <Label overflow fontSize={normalText} color={darkerGray}>
                {fantasyName}
              </Label>
            )}
            <LabelCopyToClipboard color={darkGray} text={document}>
              <Label overflow fontSize={normalText} color={darkerGray}>
                {item.type === 'individual'
                  ? `CPF ${document}`
                  : `CNPJ ${document}`}
              </Label>
            </LabelCopyToClipboard>
            <LabelCopyToClipboard color={darkGray} text={id} hoverText>
              <Label overflow fontSize={normalText} color={darkerGray}>
                {`ID ${id}`}
              </Label>
            </LabelCopyToClipboard>
          </>
        )}
      </Container>
      <InfoCardContainer>
        {infoResume.map((info, index) => (
          <CustomCard
            onClick={info.onClick && info.onClick}
            key={index}
            data-test={`seller-detail-card-${info.label}`}
          >
            {info.label && (
              <Label bold color={darkGray} fontSize={normalText} uppercase>
                {info.label}
              </Label>
            )}
            {info.icon && <ErrorIcon src={info.icon} />}
            {!info.isEmpty ? (
              isRequesting ? (
                <HeaderLabelCardLoader />
              ) : (
                <Label
                  color={info.color || darkerGray}
                  fontSize={bigText}
                  uppercase
                  bold
                >
                  {info.info}
                </Label>
              )
            ) : (
              <>
                {info.title &&
                  (isRequesting ? (
                    <HeaderLabelCardLoader />
                  ) : (
                    <Label breakLine bold={info.boldTitle} color={darkGray}>
                      {info.title}
                    </Label>
                  ))}
                <Label color={darkGray}>{info.info}</Label>
              </>
            )}
          </CustomCard>
        ))}
      </InfoCardContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${lighterGray};
  border-radius: 10px;
  width: 100%;
  height: 135px;
  padding: 20px 20px 20px 30px;
  display: flex;
`

const Container = styled.div`
  width: 30%;
`

const LabelName = styled(Label)`
  margin-bottom: 9px;
`

const InfoCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 74px;
`

const ErrorIcon = styled(ReactSVG)`
  margin-bottom: ${smallMargin};
`

const CustomCard = styled(Card)`
  height: 100px;
  justify-content: center;
  text-align: center;
  margin: 0 5px;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`

HeaderSellerDetail.defaultProps = {
  infoResume: [
    {
      isEmpty: false,
      label: 'Status',
      info: 'Teste'
    },
    {
      isEmpty: false,
      label: 'Plano Ativo',
      info: 'Teste'
    },
    {
      isEmpty: false,
      label: 'Vendas Aprovadas Hoje',
      info: 'Teste'
    }
  ]
}

HeaderSellerDetail.propTypes = {
  item: PropTypes.object,
  infoResume: PropTypes.array,
  isRequesting: PropTypes.bool
}

export { HeaderSellerDetail }
