import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import { darkGray, darkerGray, white } from '../../Styles/settings/Constants'

const IconButton = ({ type, onClick, children, black, white, outline, className, src, ...props }) => (
  <ButtonDefault
    type={type}
    onClick={() => onClick()}
    black={black}
    white={white}
    outline={outline}
    src={src}
    className={className}
    {...props}
  >
    {src
      ? <ReactSVG src={src} />
      : children
    }
  </ButtonDefault>
)

IconButton.defaultProps = {
  type: 'button'
}

IconButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  black: PropTypes.bool,
  white: PropTypes.bool,
  outline: PropTypes.bool,
  className: PropTypes.string
}

const ButtonDefault = styled.button`
  background-color: ${props => props.theme.color};
  border-radius: 5px;
  color: ${white};
  border: 0;

  ${({ black }) => black && css`
    background-color: ${darkerGray};
  `}

  ${({ white }) => white && css`
    border: 1px solid ${darkGray};
    background-color: transparent;
    color: ${darkGray};
  `}

  ${({ outline }) => outline && css`
    border: 1px solid ${props => props.theme.color};
    background-color: transparent;
    color: ${props => props.theme.color};
    width: 30px;
    height: 30px;
  `}

  ${ReactSVG} {
    svg {
      fill: ${({ theme, outline }) => outline ? theme.color : white};

      ${({ white }) => white && css`
        fill: ${darkerGray};
      `}
    }
  }

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }
`

export default IconButton
