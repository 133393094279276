import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { toMoney } from 'src/Utils/Utils'
import List from 'src/UIComponents/List/List'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import { SellersRankingByAmountLoader } from 'src/UIComponents/KPIDetails/SellersRankingByAmountLoader'

import {
  darkGray,
  normalText,
  smallText
} from 'src/Styles/settings/Constants'

const SellersRankingByAmount = ({ title, highligth, items, isLoading, noCard }) => {
  const getItems = () => {
    return items && items.map(item => (
      <WrapperList key={item.name || item.key}>
        <Label bold color={darkGray} fontSize={smallText}>{item.name || item.key}</Label>
        <Label color={darkGray} fontSize={smallText}>{toMoney(item.value / 100)}</Label>
      </WrapperList>
    ))
  }

  if (isLoading) {
    return (
      <SellersRankingByAmountLoader />
    )
  }

  if (noCard) {
    return (
      <>
        <Label
          uppercase
          bold
          color={darkGray}
          fontSize={smallText}
          textAlign='center'
        >
          {title}
          {highligth && (
            <Label
              uppercase
              bold
              fontSize={smallText}
            >
              {highligth}
            </Label>
          )}
        </Label>
        <br />
        <List line items={getItems()} />
      </>
    )
  }

  return (
    <Card>
      <Label
        uppercase
        bold
        color={darkGray}
        fontSize={normalText}
        textAlign='center'
      >
        {title}
      </Label>
      <br />
      <List line items={getItems()} />
    </Card>
  )
}

const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
`

SellersRankingByAmount.defaultProps = {
  title: 'Estabelecimentos com maior volume de chargeback',
  noCard: false
}

SellersRankingByAmount.propTypes = {
  title: PropTypes.string,
  highligth: PropTypes.string,
  items: PropTypes.array,
  noCard: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default SellersRankingByAmount
