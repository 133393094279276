import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'

const HeaderSellerInfoLoader = (props) => (
  <Wrapper
    width={235}
    height={115}
    viewBox='4 0 235 115'
    speed={4}
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='150' height='22' />
    <rect x='0' y='44' rx='5' ry='5' width='190' height='14' />
    <rect x='0' y='68' rx='5' ry='5' width='174' height='14' />
    <rect x='0' y='92' rx='5' ry='5' width='215' height='14' />
  </Wrapper>
)

const Wrapper = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  margin-left: -2rem;
`

export default HeaderSellerInfoLoader
