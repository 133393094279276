import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'

import { green, red } from '../../Styles/settings/Constants'
import Icon from 'src/UIComponents/Icon/Icon'

const InputForm = ({ children, id, label, onBlur, onChange, placeholder, type, value, disabled, isValid, hasError, errorMessage, className, lock, ...props }) => (
  <Container>
    {!disabled && isValid && (
      <StyledIcon
        src='/assets/icons/system/check.svg'
        hasOffsetBottom={(errorMessage && errorMessage.length > 0)} />
    )}
    <FloatingLabelInput
      id={id}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      isValid={isValid}
      value={value}
      disabled={disabled}
      hasError={hasError}
      errorMessage={errorMessage}
      className={className}
      lock={lock}
      {...props}
    />
    { children }
    {hasError && <Error data-test={`input-error-${id || label}`} >{errorMessage}</Error>}
  </Container>
)

InputForm.defaultProps = {
  type: 'text'
}

InputForm.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  hasError: PropTypes.bool,
  lock: PropTypes.bool,
  errorMessage: PropTypes.string,
  props: PropTypes.object,
  className: PropTypes.string
}

const Container = styled.div`
  font-size: 20px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  right: 4px;

  svg {
    fill: ${green};
  }
`

const Error = styled.span`
  margin-top: 4px;
  color: ${red};
  font-size: 12px;
  display: flex;
  line-height: 16px;
`

export default InputForm
