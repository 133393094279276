import React from 'react'
import ContentLoader from 'react-content-loader'

export const TotalAmountLoader = () => (
  <ContentLoader
    speed={4}
    width={172}
    height={50}
    viewBox='0 0 172 50'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='6' y='10' rx='5' ry='5' width='160' height='18' />
    <rect x='26' y='36' rx='5' ry='5' width='120' height='14' />
  </ContentLoader>
)
