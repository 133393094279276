import React from 'react'
import ContentLoader from 'react-content-loader'

export const LastCommissionLoader = () => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={80}
    viewBox='0 0 250 80'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
  >
    <rect x='13' y='12' rx='5' ry='5' width='174' height='12' />
    <rect x='154' y='32' rx='5' ry='5' width='93' height='14' />
    <rect x='195' y='12' rx='5' ry='5' width='52' height='12' />
    <rect x='168' y='55' rx='5' ry='5' width='78' height='12' />
  </ContentLoader>
)
