import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { toMoney, toPercentage } from '../../Utils/Utils'

import List from 'src/UIComponents/List/List'
import Label from 'src/UIComponents/Label/Label'

import { lightGray, darkGray, normalText, smallMargin, margin } from 'src/Styles/settings/Constants'
import { TransactionsBySellerLoader } from './TransactionsBySellerLoader'

const TransactionsBySeller = ({ items, isLoading }) => {
  if (isLoading) {
    return (
      <TransactionsBySellerLoader />
    )
  }

  const getItems = () => {
    let itemsToRender = []
    items.forEach(({ value, percent, key }, id) => {
      itemsToRender.push(
        <ItemContainer key={id} data-test={`transaction-by-seller-item-${id}`}>
          <SellerName bold color={darkGray} fontSize={normalText}>{key.toLowerCase()}</SellerName>
          <Label color={darkGray} fontSize={normalText}>
            {`${toMoney(value / 100)} (${toPercentage(percent, 0)} das vendas)`}
          </Label>
        </ItemContainer>
      )
    })
    return itemsToRender
  }

  return (
    <List items={getItems()} data-test='transaction-by-seller' />
  )
}

TransactionsBySeller.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool
}

const ItemContainer = styled.div`
  display: flex;
  padding: ${smallMargin};
  flex-direction: column;

  div > & {
    border-bottom: 1px solid ${lightGray};
  }

  div:last-child > & {
    border-bottom: none;
  }
`

const SellerName = styled(Label)`
  text-transform: capitalize;
  line-height: ${margin};
`

export default TransactionsBySeller
