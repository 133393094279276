import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput'
import { darkGray } from 'src/Styles/settings/Constants'
import Icon from 'src/UIComponents/Icon/Icon'

const InputDatePicker = ({
  id,
  label,
  subLabel,
  // eslint-disable-next-line no-unused-vars
  onBlur,
  onFocus,
  onChange,
  placeholder,
  type,
  value,
  disabled,
  isValid,
  hasError,
  errorMessage,
  showIcon,
  ...props
}) => {
  const maskDate = (value) => {
    let v = value.replace(/\D/g, '').slice(0, 8)
    if (v.length >= 5 && v.length <= 8) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)}`
    } else if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`
    }
    return v
  }

  const [inputValue, setInputValue] = useState(maskDate(value))

  const handleChange = (e) => {
    const { value } = e.target
    setInputValue(maskDate(value))
    onChange(maskDate(value))
  }

  useEffect(() => {
    setInputValue(maskDate(value))
  }, [value])

  return (
    <Container>
      <FloatingLabelInput
        id={id}
        label={label}
        onFocus={onFocus}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        isValid={isValid}
        value={inputValue}
        disabled={disabled}
        hasError={hasError}
        errorMessage={errorMessage}
        showIcon={showIcon}
        {...props}
      />
      {hasError && <Error data-test={`input-error-${id || label}`}>{errorMessage}</Error>}
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
      {showIcon && <IconWrapper onClick={onFocus} src={`/assets/icons/others/calendar.svg`} />}
    </Container>
  )
}

InputDatePicker.defaultProps = {
  format: 'dd/MM/yyyy',
  value: '',
  onChange: () => {
    /* noop */
  },
  onBlur: () => {
    /* noop */
  }
}

InputDatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showIcon: PropTypes.bool
}

const Container = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`

const Error = styled.span`
  color: #fc3d3d;
  font-size: 12px;
  display: flex;
  line-height: 16px;
  margin-top: 4px;
`

const SubLabel = styled.span`
  color: ${darkGray};
  font-size: 10px;
`

const IconWrapper = styled(Icon)`
  position: absolute;
  top: -5px;
  right: 2px;
`

export default InputDatePicker
