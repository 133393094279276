import React from 'react'
import Proptypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { textBold, normalText } from 'src/Styles/settings/Constants'

const ItemMenu = ({ to, title, colorText = '#9A9A9A', isSellerMenu }) => (
  <Item isSellerMenu={isSellerMenu}>
    <NavLink
      to={to}
      activeClassName='active'
      data-test={`menu-item-${title}`}
      data-tracking={`menu-item-${title}`}
    >
      <Text colorText={colorText}>{title}</Text>
    </NavLink>
  </Item>
)

ItemMenu.propTypes = {
  to: Proptypes.string,
  title: Proptypes.string,
  colorText: Proptypes.string,
  isSellerMenu: Proptypes.bool
}
const Item = styled.li`
  display: flex;
  height: 5rem;


  ${({ isSellerMenu }) => isSellerMenu && css`
    height: 3.3rem;
    padding: 0 10px;

    &:first-child{
      padding-left: 0px;
    }
  `}

  ${({ isSellerMenu }) => isSellerMenu && css`
    a {
      display: flex;
      align-items: center;
    }
  `}

  a:hover {
    text-decoration: none;
    font-weight: ${textBold};
  }


  .active {
    border-bottom: 4px solid ${props => props.theme.color};
    ${({ isSellerMenu }) => isSellerMenu && css`
        border-bottom: none;
    `}

    span {
      color: ${props => props.theme.color};
      font-weight: ${textBold};
    }
  }
`

const Text = styled.span`
  color: ${props => props.colorText};
  font-size: ${normalText};
  text-transform: uppercase;
`

export default ItemMenu
