import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

const Icon = ({ src, onClick, height, color, className }) => (
  <IconStyled
    className={className}
    src={src}
    onClick={onClick ? () => onClick() : null}
    height={height}
    color={color}
  />
)

Icon.propTypes = {
  src: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
}

const IconStyled = styled(ReactSVG)`
  width: fit-content;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}

  svg {
    fill: ${props => props.color || props.theme.color};

    ${({ height }) => height && css`
      width: auto;
      height: ${height};
    `}
  }
`

export default Icon
