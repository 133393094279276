import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import SelectSellerContext from 'src/Components/SelectSellerContext/SelectSellerContext'
import {
  lighterGray,
  darkGray,
  gray,
  normalText,
  spaceMargin,
  smallerText
} from 'src/Styles/settings/Constants'
import { Beacon } from '../../Components/OnboardingHints/Beacon'
import { useHistory } from 'react-router-dom'

const HeaderSellerUI = ({ marketplace }) => {
  const history = useHistory()

  const handleBackRedirect = () => {
    history.push('/')
  }

  return (
    <Container data-test='header-seller-ui'>
      <TextContainer>
        <Beacon hint='voltar-marketplace' wrapperProps={{ top: '30px' }} />
        <Text data-test='header-seller-ui-voltar' onClick={handleBackRedirect}>
          <Icon src='/assets/icons/arrow/medium-arrow-left.svg' />
          Voltar para <strong> {marketplace.name} </strong>
          {marketplace.document && (
            <span>CNPJ:{marketplace.document?.toCnpj()}</span>
          )}
        </Text>
      </TextContainer>
      <SelectSellerContext />
    </Container>
  )
}

HeaderSellerUI.propTypes = {
  marketplace: PropTypes.object
}

const Container = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${lighterGray};
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
`
const TextContainer = styled.div`
  display: flex;
`

const Text = styled.p`
  font-size: ${normalText};
  color: ${gray};
  display: flex;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;

  > strong {
    margin-right: ${spaceMargin};
    margin-left: ${spaceMargin};
    color: ${darkGray};
  }

  > span {
    font-size: ${smallerText};
    margin-top: 2px;
    margin-right: ${spaceMargin};
  }
`

const Icon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  margin-right: ${spaceMargin};
`
export { HeaderSellerUI }
