import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import List from 'src/UIComponents/List/List'
import Label from 'src/UIComponents/Label/Label'
import { getCardBrandImg, toMoney, toPercentage } from 'src/Utils/Utils'

import { darkGray, smallerText, normalText, biggestText } from 'src/Styles/settings/Constants'
import { TransactionsByCardBrandLoader } from './TransactionsByCardBrandLoader'

const TransactionByCardBrand = ({ items, isLoading }) => {
  if (isLoading) {
    return (
      <TransactionsByCardBrandLoader />
    )
  }

  return (
    <List line items={
      items && items.map(({ key, value, count, percent }, index) => (
        <ItemContainer data-test='transactions-by-cardBrand-item' >
          <GroupLeft data-test='transactions-by-cardBrand-image'>
            <Label color={darkGray} fontSize={biggestText}>{`${index + 1}º`}</Label>
            <CardBrand src={getCardBrandImg(key)} />
          </GroupLeft>
          <GroupRight data-test='transactions-by-cardBrand-labels'>
            <Label bold color={darkGray} textAlign='right' fontSize={normalText}>{toMoney(value)}</Label>
            <Label color={darkGray} fontSize={smallerText} textAlign='right'>{count} vendas ({toPercentage(percent)})</Label>
          </GroupRight>
        </ItemContainer>
      ))
    } />
  )
}

TransactionByCardBrand.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool
}

export default TransactionByCardBrand

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardBrand = styled.img`
  object-fit: contain;
  height: 30px;
  width: 35px;
  margin-left: 20px;
`

const GroupLeft = styled.div`
  display: flex;
`

const GroupRight = styled.div`
  display: grid;
`
