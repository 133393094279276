import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from '../Grid/Grid'

import {
  darkGray,
  darkerGray,
  textBold,
  smallText,
  lightGray
} from '../../Styles/settings/Constants'

const ListSellers = ({ data }) => (
  <Container>
    <ListHeader noMargin>
      <Grid.Col cols={6}>
        <Label>CPF/CNPJ</Label>
      </Grid.Col>
      <Grid.Col cols={10}>
        <Label>Estabelecimento</Label>
      </Grid.Col>
    </ListHeader>

    <List>
      {data.map((seller, index) => {
        const businessName =
          seller.type !== 'individual' && seller.business_name
        const name =
          seller.type === 'individual'
            ? `${seller.first_name} ${seller.last_name}`
            : seller.statement_descriptor
        const document =
          seller.type === 'individual'
            ? seller.taxpayer_id.toCPF()
            : seller.ein?.toCnpj()

        return (
          <Item key={index}>
            <Grid.Row height={'100%'}>
              <Grid.Col cols={6}>
                <Document>{document}</Document>
              </Grid.Col>
              <SellerInfoWrapper cols={10}>
                <SellerInfo>
                  {seller.type === 'individual' && <Bold>{name}</Bold>}
                  {seller.type === 'business' && (
                    <Fragment>
                      <Bold>{name}</Bold>
                      <Text>{businessName}</Text>
                    </Fragment>
                  )}
                </SellerInfo>
              </SellerInfoWrapper>
            </Grid.Row>
          </Item>
        )
      })}
    </List>
  </Container>
)

ListSellers.propTypes = {
  data: PropTypes.array
}

const Container = styled.div`
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
`

const Label = styled.span`
  color: ${darkerGray};
  font-size: ${smallText};
`

const ListHeader = styled(Grid.Row)`
  padding-bottom: 0.7rem;
  border-bottom: 1px solid ${lightGray};
`

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Item = styled.li`
  list-style: none;
  height: 50px;
`

const Text = styled.p`
  font-size: ${smallText};
  color: ${darkGray};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`

const Document = styled.p`
  font-size: ${smallText};
  color: ${darkGray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: 100%;
`

const SellerInfo = styled.div`
  margin: auto 0;
`

const SellerInfoWrapper = styled(Grid.Col)`
  display: flex;
`

const Bold = styled(Text)`
  font-weight: ${textBold};
`

export default ListSellers
