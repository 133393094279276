import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'
import { UsersList } from 'src/UIComponents/HeaderUser/UsersList'

import Card from 'src/UIComponents/Card/Card'
import Avatar from 'src/UIComponents/Avatar/Avatar'
import ButtonDefault from 'src/UIComponents/Button/ButtonDefault'

import { darkGray, darkerGray, smallText, normalText, textBold } from 'src/Styles/settings/Constants'
import appConfig from 'src/Configs/appConfig'
import { isLocal } from 'src/Utils/Utils'

const UsersMenu = ({ user, handleClose, logout, users, onUserSelect, onMyProfileClick }) => {
  UsersMenu.handleClickOutside = () => handleClose()

  const addSlugAccount = () => {
    handleClose()

    if (isLocal()) {
      window.open(`http://${appConfig.domain}:8080/adicionar-conta`)
    } else {
      window.open(`https://${appConfig.domain}/adicionar-conta`)
    }
  }

  return (
    <StyledCard onClick={e => e.stopPropagation()} withShadow>
      <Header>
        <Close data-tracking='SwitchAccount > Logout' onClick={() => logout()} data-test='button-logout'>Sair</Close>
      </Header>
      <CardUserInfo>
        <Avatar size='big' fullName={user.name} />
        <Welcome>{`Olá, ${user.name}`}</Welcome>
        <UserName>{user.userName}</UserName>
        <Button data-test='header-my-profile-btn' data-tracking='SwitchAccount > Profile' link onClick={onMyProfileClick}>
          Meu perfil
        </Button>
        <Line />
      </CardUserInfo>
      <UsersList users={users} onUserSelect={onUserSelect} />
      <Footer>
        <Span data-tracking='SwitchAccount > AddAccount' onClick={() => addSlugAccount()}>
          <strong>Adicionar</strong> Conta
        </Span>
      </Footer>
    </StyledCard>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => UsersMenu.handleClickOutside
}

UsersMenu.propTypes = {
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  logout: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  onUserSelect: PropTypes.func,
  onMyProfileClick: PropTypes.func
}

const StyledCard = styled(Card)`
  position: absolute;
  width: 249px;
  border-radius: 10px;
  right: 0;
  top: 28px;
  z-index: 999;
  cursor: initial;
  padding: 0;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.3);
`

const Header = styled.div`
  display: flex;
  padding-top: 17px;
  padding-right: 20px;
  justify-content: flex-end;
  width: 100%;
`

const Close = styled.p`
  font-size: ${smallText};
  line-height: 16px;
  color: ${darkerGray};
  height: 20px;
  cursor: pointer;
  width: 30px;
  text-align: right;
`

const CardUserInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const Welcome = styled.span`
  margin-top: 10px;
  font-size: ${normalText};
  line-height: 20px;
  font-weight: ${textBold};
  color: ${darkerGray};
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: center;
`

const UserName = styled.span`
  font-size: ${smallText};
  line-height: 16px;
  color: ${darkGray};
  margin-bottom: 7px;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: center;
`

const Button = styled(ButtonDefault)`
  font-size: ${smallText};
`

const Line = styled.div`
  background-color: #B8B8B8;
  height: 0.3px;
  width: calc(100% - 32px);
  margin-top: 20px;
  opacity: 0.5;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.4);
  align-self: flex-end;
  margin-top: auto;
  border-radius: 0 0 10px 10px;
`

const Span = styled.span`
  font-size: ${smallText};
  color: ${darkGray};
  cursor: pointer;

  > strong {
    font-weight: ${textBold};
  }
`

const EnhancedUsersMenu = onClickOutside(UsersMenu, clickOutsideConfig)

export { EnhancedUsersMenu }
