import * as PropTypes from 'prop-types'

import React, { useState } from 'react'
import { bigText, biggerText, darkGray, normalText, smallText, smallerText, white } from 'src/Styles/settings/Constants'
import styled, { css } from 'styled-components'

import { CopyToClipboard } from 'react-copy-to-clipboard'

const LabelCopyToClipboard = ({ children, text, size, hoverText, color }) => {
  const [visible, setVisible] = useState(false)
  const onCopy = () => {
    setVisible(true)
    setTimeout(() => setVisible(false), 2000)
  }
  return (
    <StyledContainer>
      <StyledCopyToClipboard
        hoverText={hoverText}
        size={size}
        text={text}
        onCopy={() => onCopy()}
        color={color}
        name='copyToClipboard'>
        <div>
          {children}
        </div>
      </StyledCopyToClipboard>
      <LabelSuccess hoverText={hoverText} visible={visible}>Copiado</LabelSuccess>
    </StyledContainer>
  )
}

LabelCopyToClipboard.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'big', 'bigger']),
  hoverText: PropTypes.bool,
  color: PropTypes.string
}

const LabelSuccess = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${darkGray};
  border-radius: 5px;
  font-size: ${smallText};
  color: ${white};
  padding: 0 5px;
  margin-left: 10px;
  opacity: 0;
  visibility: hidden;

  ${({ hoverText }) => hoverText && css`
    position: absolute;
    right: 0;
    height: 100%;
  `}

  ${({ visible }) => visible && css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in;
  `}
`
const StyledContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const StyledCopyToClipboard = styled(CopyToClipboard)`
  cursor: pointer;
  padding: 0 4px;
  margin: 0 -4px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${darkGray};
  white-space: nowrap;

  ${({ hoverText }) => hoverText && css`
    text-overflow: initial;
    overflow: initial;
  `}

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ size }) => {
    switch (size) {
    case 'big':
      return `font-size: ${bigText}`
    case 'bigger':
      return `font-size: ${biggerText}`
    case 'normal':
      return `font-size: ${normalText}`
    case 'smaller':
      return `font-size: ${smallerText}`
    }
  }}

  :hover {
    background-color: #DFDFDF;
    border-radius: 5px;
    transition: background-color 0.2s;
  }
`

export default LabelCopyToClipboard
