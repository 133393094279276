import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { isMobile } from 'react-device-detect'

import { middleMargin, bigMargin, largeMargin, smallMargin, margin } from '../../Styles/settings/Constants'

const Grid = ({ children, width, noPadding, className, id, marginTop, marginBottom }) => (
  <GridWrapper id={id} width={width} noPadding={noPadding} className={className} marginTop={marginTop} marginBottom={marginBottom}>
    {children}
  </GridWrapper>
)

let lastElementEnd = 0

Grid.defaultProps = {
  width: '100%'
}

Grid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.string,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string
}

const mobileGridColumnsQty = 8
const browserGridColumnsQty = 16

const GridWrapper = styled.div`
  width: ${({ width }) => width};
  padding: 0 ${bigMargin};

  ${({ noPadding }) => noPadding && css`
    padding: 0;
  `}

  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop}
  `}

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom} !important
  `}
`

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${isMobile ? mobileGridColumnsQty : browserGridColumnsQty}, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  margin-bottom: ${middleMargin};


  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom} !important
  `}
  
  ${({ smallMarginBottom }) => smallMarginBottom && css`
    margin-bottom: ${smallMargin};
  `}

  ${({ bigMarginBottom }) => bigMarginBottom && css`
    margin-bottom: ${bigMargin}
  `}

  ${({ largeMarginBottom }) => largeMarginBottom && css`
    margin-bottom: ${largeMargin}
  `}

  ${({ middleMarginBottom }) => middleMarginBottom && css`
    margin-bottom: ${margin}
  `}

  ${({ noMargin }) => noMargin && css`
    margin-bottom: 0
  `}

  :last-child {
    margin-bottom: 0;
  }

  ${({ height }) => height && css`
    height: ${height};
  `}

  ${({ auto }) => auto && css`
    grid-template-columns: repeat(auto-fit, minmax(0, auto));
  `}

  ${() => { lastElementEnd = 0 }}
`

const ColWrapper = styled.div`
  grid-column: auto;

  ${({ cols, offset }) => cols && !isMobile && css`
    grid-column: ${offset ? (offset + lastElementEnd + 1) + ' / ' : ''} span ${cols};
  `}

  ${({ mobileCols }) => mobileCols && isMobile && css`
    grid-column: span ${mobileCols};
  `}

  ${({ cols, offset = 0 }) => {
    lastElementEnd = lastElementEnd + cols + offset
  }}
`

Grid.Col = ColWrapper
Grid.Row = RowWrapper

export default Grid
