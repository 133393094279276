import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'
import Title from '../Title/Title'
import { darkGray } from 'src/Styles/settings/Constants'
import Label from 'src/UIComponents/Label/Label'

const HeaderSidebar = ({ goBack, title, subTitle, handleClose }) => (
  <Container>
    <Grid.Row>
      <Grid.Col cols={1}>
        {goBack && (
          <GoBackIcon
            onClick={() => goBack()}
            src='/assets/icons/arrow/arrow-left.svg'
            data-test='sidebar-goBack-btn'
          />
        )}
      </Grid.Col>
      <TitleContainer cols={14}>
        {title && (
          <Title big data-test='sidebar-title'>{title}</Title>
        )}
        {subTitle && (
          <Label
            color={darkGray}
            textAlign='center'
            data-test='sidebar-subtitle'
          >
            {subTitle}
          </Label>
        )}
      </TitleContainer>
      <Grid.Col cols={1}>
        <CloseSidebarIcon
          onClick={handleClose}
          src='/assets/icons/actions/close.svg'
          data-test='sidebar-close-btn'
        />
      </Grid.Col>
    </Grid.Row>
  </Container>
)

HeaderSidebar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.string,
  goBack: PropTypes.func,
  handleClose: PropTypes.func
}

const Container = styled(Grid)`
  display: flex;
  height: 65px;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;

  ${Grid.Row} {
    width: 100%;
  }
`

const TitleContainer = styled(Grid.Col)`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const GoBackIcon = styled(ReactSVG)`
  margin-top: 5px;
  cursor: pointer;
  svg {
    fill: ${props => props.theme.color};
    height: 14px;
  }
`

const CloseSidebarIcon = styled(ReactSVG)`
  margin-top: 5px;
  cursor: pointer;
  height: 100%;

  div {
    height: 100%;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`

export default HeaderSidebar
