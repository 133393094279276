import React from 'react'
import Proptypes from 'prop-types'
import styled from 'styled-components'
import {
  lighterGray,
  normalText,
  bigText
} from 'src/Styles/settings/Constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${lighterGray};
  border-radius: 10px;
  padding: 25px;
  min-height: 110px;
  margin-bottom: 1.5rem;
`

const Title = styled.span`
  color: ${({ theme: { color } }) => color};
  font-size: ${bigText};
  font-weight: bold;
  font-family: 'Roboto';
  line-height: 20px;
  text-transform: uppercase;
`

const Content = styled.span`
  margin: 5px 0px;
  font-size: ${normalText};
  white-space: pre-line;
`

const InfoCard = (
  {
    title,
    children
  }) => {
  return (
    <Container>
      <Title>
        {title}
      </Title>
      <Content>
        {children}
      </Content>
    </Container>
  )
}

InfoCard.propTypes = {
  title: Proptypes.string,
  children: Proptypes.element
}

export default InfoCard
