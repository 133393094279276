import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import { momentToFixedPeriod } from 'src/Utils/Utils'

moment.locale('pt-BR')

const dateFormat = 'DD/MM/YYYY'

const Label = ({ period, options }) => {
  const periodKey = momentToFixedPeriod(period)

  const optionSelected = () => {
    return options.find(i => {
      return i.value === periodKey
    })
  }

  if (!optionSelected()) {
    return null
  }

  const { label, date } = optionSelected()

  return (
    <StyledLabel data-test='kpi-period-label' data-tracking='kpi-period-label'>
      {
        label &&
        <strong>{label}, </strong>
      }
      {date(period)}
    </StyledLabel>
  )
}

Label.defaultProps = {
  options: [
    {
      value: 0,
      label: 'Hoje',
      date: () => moment({ hour: 0, minute: 0, second: 0 }).format('LL')
    },
    {
      value: 1,
      label: 'Ontem',
      date: () => moment({ hour: 0, minute: 0, second: 0 })
        .subtract(1, 'day')
        .format('LL')
    },
    {
      value: 'current-week',
      label: 'Essa semana',
      date: () => {
        const from = moment().startOf('isoWeek').format(dateFormat)
        const to = moment().endOf('isoWeek').format(dateFormat)

        return `${from} a ${to}`
      }
    },
    {
      value: 'last-week',
      label: 'Semana passada',
      date: () => {
        const from = moment().subtract(1, 'weeks').startOf('isoWeek').format(dateFormat)
        const to = moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormat)

        return `${from} a ${to}`
      }
    },
    {
      value: 'current-month',
      label: 'Esse mês',
      date: () => {
        const from = moment().startOf('month').format(dateFormat)
        const to = moment().endOf('day').format(dateFormat)

        return `${from} a ${to}`
      }
    },
    {
      value: 'last-month',
      label: 'Mês passado',
      date: () => {
        const from = moment().subtract(1, 'months').startOf('month').format(dateFormat)
        const to = moment().subtract(1, 'months').endOf('month').format(dateFormat)

        return `${from} a ${to}`
      }
    },
    {
      value: 'one-year',
      label: 'Até um ano',
      date: () => {
        const from = moment().subtract(1, 'year').startOf('day').format(dateFormat)
        const to = moment().endOf('day').format(dateFormat)

        return `${from} a ${to}`
      }
    },
    {
      value: 'custom-date',
      label: '',
      date: (period) => {
        const from = moment(period.gte).format(dateFormat)
        const to = moment(period.lte).format(dateFormat)

        return `${from} a ${to}`
      }
    }
  ]
}

Label.propTypes = {
  period: PropTypes.number,
  options: PropTypes.array
}

export default Label

const StyledLabel = styled.span`
  strong {
    text-transform: uppercase;
    padding-right: 5px;
  }
`
