import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getTextPlural } from 'src/Utils/Utils'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import BarChart from '../Charts/BarChart'
import TransactionsBySeller from 'src/UIComponents/KPIDetails/TransactionsBySeller'
import TotalAmount from 'src/UIComponents/KPIDetails/TotalAmount'
import PeriodLabel from 'src/UIComponents/Label/PeriodLabel'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { darkGray, normalText } from 'src/Styles/settings/Constants'

const KPITransactionsChargedBack = ({ period, color, dataChart, topHits, amount, count, isLoading }) => (
  <Fragment>
    {!isLoading && count === 0
      ? (<SidebarContainer emptyState>
        <EmptyState
          iconSrc='/assets/icons/alerts/success.svg'
          title={'Oba! Não sofremos nenhum chargeback no\nperíodo selecionado'}
        />
      </SidebarContainer>)
      : (<SidebarContainer>
        <Grid.Row>
          <DateContainer cols={16}>
            <PeriodLabel period={period} />
          </DateContainer>
        </Grid.Row>
        {amount && (
          <Grid.Row auto>
            <TotalAmount
              amount={amount}
              count={`${count} ${getTextPlural('transaction', count)}`}
              isLoading={isLoading}
            />
          </Grid.Row>
        )}
        {dataChart.length > 0 && (
          <Grid.Row auto>
            <Card data-test='kpi-chargedback-card-distribution'>
              <Label
                bold
                uppercase
                color={darkGray}
                fontSize={normalText}
              >
                Distribuição
              </Label>
              <BarChart color={color} width='100%' layout='horizontal' height={252} data={dataChart} isLoading={isLoading} />
            </Card>
          </Grid.Row>
        )}
        {(isLoading || (topHits && topHits.length)) > 0 && (
          <Grid.Row auto>
            <Card data-test='kpi-chargedback-card-volume'>
              <Label
                bold
                uppercase
                color={darkGray}
                fontSize={normalText}
              >
                Estabelecimentos com maior
              </Label>
              <Label
                bold
                uppercase
                color={darkGray}
                fontSize={normalText}
              >
                Volume de Chargeback
              </Label>
              <Grid.Row />
              <TransactionsBySeller items={topHits} isLoading={isLoading} />
            </Card>
          </Grid.Row>
        )}
      </SidebarContainer>)
    }
  </Fragment>
)

KPITransactionsChargedBack.propTypes = {
  period: PropTypes.number,
  color: PropTypes.string,
  dataChart: PropTypes.array,
  topHits: PropTypes.array,
  amount: PropTypes.string,
  count: PropTypes.string,
  isLoading: PropTypes.bool
}

export default KPITransactionsChargedBack

const DateContainer = styled(Grid.Col)`
  text-align: center;
  font-size: ${normalText};
`
