import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getTextPlural } from 'src/Utils/Utils'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import TotalAmount from 'src/UIComponents/KPIDetails/TotalAmount'
import PeriodLabel from 'src/UIComponents/Label/PeriodLabel'
import PieChart from 'src/UIComponents/Charts/PieChart'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { darkGray, normalText } from 'src/Styles/settings/Constants'

const KPITransactionsFailed = ({ period, color, amount, pieChart, count, isLoading }) => (
  <Fragment>
    {!isLoading && count === 0
      ? (<SidebarContainer emptyState>
        <EmptyState
          iconSrc='/assets/icons/alerts/success.svg'
          title={'Que bom! Nenhuma venda falhada no\nperíodo selecionado'}
        />
      </SidebarContainer>)
      : (<SidebarContainer>
        <Grid.Row>
          <DateContainer cols={16}>
            <PeriodLabel period={period} />
          </DateContainer>
        </Grid.Row>
        <Grid.Row auto>
          <TotalAmount
            amount={amount}
            count={`${count} ${getTextPlural('transaction', count)}`}
            isLoading={isLoading}
          />
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='failed-kpi-capture'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Falhas por meios de captura
            </Label>
            <PieChart color={color} width='100%' height={320} data={pieChart} isLoading={isLoading} />
          </Card>
        </Grid.Row>
      </SidebarContainer>)
    }
  </Fragment>
)

KPITransactionsFailed.propTypes = {
  period: PropTypes.number,
  color: PropTypes.string,
  pieChart: PropTypes.array,
  amount: PropTypes.string,
  count: PropTypes.string,
  isLoading: PropTypes.bool
}

export default KPITransactionsFailed

const DateContainer = styled(Grid.Col)`
  text-align: center;
  font-size: ${normalText};
`
