import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import LineCardLoader from 'src/UIComponents/LineCard/LineCardLoading'

import {
  darkGray,
  green,
  normalText,
  red,
  smallPadding,
  textBold,
  white,
  spaceMargin,
  gray,
  middlePadding
} from 'src/Styles/settings/Constants'

const LineCard = ({
  status,
  label,
  children,
  backgroundColor,
  icon,
  isLoading,
  loadingForm,
  noVerticalMargin,
  shadow,
  ...props
}) => {
  const titleLineCard = () => {
    const splittedLabel = label.split(' ')
    const firstWord = splittedLabel.shift()
    const otherWords = splittedLabel.join(' ')

    return (
      <Fragment>
        {icon && <StyledSVG width={icon.width} height={icon.height} themeColor={status === 'neutral'} src={icon.src} />}
        <Label>{firstWord}</Label>
        {otherWords && (
          <Label bold themeColor={status === 'neutral'}> {otherWords}</Label>
        )}
      </Fragment>
    )
  }

  return (
    <Div>
      <LineCardContainer data-test={`line_card-${typeof label === 'string' ? label : props.id}`} {...props}>
        {label && (
          <LabelWrapper backgroundColor={backgroundColor} status={status}>
            {typeof label === 'string' ? titleLineCard() : label}
          </LabelWrapper>
        )}
        <LineCardWrapper shadow={shadow} status={status}>
          <ContentWrapper noVerticalMargin={noVerticalMargin}>
            {isLoading && (
              <LoaderContainer>
                <LineCardLoader loadingForm={loadingForm} />
              </LoaderContainer>
            )}
            {!isLoading && children}
          </ContentWrapper>
        </LineCardWrapper>
      </LineCardContainer>
    </Div>
  )
}

const Div = styled.div`
  padding-top: ${middlePadding};
`

const LineCardContainer = styled.div`
  position: relative;
`

const LineCardWrapper = styled.div`
  border: 1px solid;
  border-radius: 7px;
  position: relative;
  overflow: auto;
  border-color: ${gray};

  ${({ status, theme }) => {
    if (status === 'error') {
      return css`
        border-color: ${red};
      `
    } else if (status === 'success') {
      return css`
        background-color: ${white};
        border-color: ${green};
      `
    } else if (status === 'neutral') {
      return css`
        border-color: ${theme.color};
      `
    }
  }}

  ${({ shadow }) => shadow && css`
    background-color: ${white};
    border: none;
    box-shadow: 0 7px 9px rgba(0, 0, 0, 0.07);
  `}
`

const LabelWrapper = styled.div`
  top: -15px;
  left: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor || white};
  padding: 0.3rem ${smallPadding};
  font-size: ${normalText};
  color: ${darkGray};
  position: absolute;
  border-radius: 2px;
  z-index: 1;

  ${({ status }) => {
    if (status === 'error') {
      return css`
        color: ${red};
      `
    } else if (status === 'success') {
      return css`
        color: ${white};

        svg {
          fill: ${white};
        }
      `
    }
  }}
`

const Label = styled.span`
  ${({ bold }) => bold && css`
    font-weight: ${textBold};
  `}

  ${({ themeColor }) => themeColor && css`
    color: ${({ theme }) => theme.color};
  `}
`

const ContentWrapper = styled.div`
  ${({ noVerticalMargin }) => noVerticalMargin && css`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const StyledSVG = styled(ReactSVG)`
  display: inline-block;
  margin-right: ${spaceMargin};

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  ${({ themeColor }) => themeColor && css`
    svg {
      fill: ${({ theme }) => theme.color};
    }
  `}
`

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
`

LineCard.propTypes = {
  status: PropTypes.oneOf(['error', 'success', 'neutral']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  icon: PropTypes.object,
  isLoading: PropTypes.bool,
  noVerticalMargin: PropTypes.bool,
  shadow: PropTypes.bool,
  loadingForm: PropTypes.bool
}

export default LineCard
