import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Avatar from 'src/UIComponents/Avatar/Avatar'
import {
  darkGray,
  gray60Percent,
  lightestGray,
  middlePadding,
  normalPadding,
  red,
  smallMargin,
  smallText
} from 'src/Styles/settings/Constants'

const UsersList = ({ users, onUserSelect }) => (
  <UList>
    {users.map(user => {
      const {
        marketplaceName,
        username,
        marketplaceColor,
        disconnected,
        userId
      } = user

      return (
        <ListItem data-tracking='SwitchAccount > SelectMarketplace' key={userId} onClick={() => onUserSelect(user)}>
          <Avatar fullName={marketplaceName || ''} size='small' backgroundColor={marketplaceColor} />
          <UserInfoWrapper>
            <Name>
              {marketplaceName}
            </Name>
            <Email>
              {username}
            </Email>
            {disconnected && (
              <DisconnectedFlag>
                Desconectado
              </DisconnectedFlag>
            )}
          </UserInfoWrapper>
        </ListItem>
      )
    })}
  </UList>
)

const UList = styled.ul`
  max-height: 189px;
  overflow-y: auto;
  width: 100%;
`

const ListItem = styled.li`
  cursor: pointer;
  display: flex;
  padding: ${middlePadding} ${normalPadding};
  background: #F1F1F1;
  position: relative;

  &:hover {
    background: ${lightestGray}
  }

  &:after {
    content: '';
    position: absolute;
    min-height: 1px;
    background: ${gray60Percent};
    top: 0;
    width: calc(100% - calc(${normalPadding} * 2));
    opacity: .5;
  }
`

const UserInfoWrapper = styled.div`
  margin-left: ${smallMargin};
  display: flex;
  flex-direction: column;
  width: 80%;
`

const Name = styled.span`
  font-size: ${smallText};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Email = styled.span`
  color: ${darkGray};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const DisconnectedFlag = styled.span`
  color: ${red};
`

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  onUserSelect: PropTypes.func
}

export { UsersList }
