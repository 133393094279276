import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'

import { darkGray, biggestText, smallPadding, normalText } from 'src/Styles/settings/Constants'
import { TotalAmountLoader } from './TotalAmountLoader'

const TotalAmount = ({ title, amount, count, isLoading }) => (
  <Card padding={smallPadding} data-test='total-amount-card'>
    <Label
      bold
      uppercase
      color={darkGray}
    >
      {title}
    </Label>
    {isLoading && <TotalAmountLoader />}
    {!isLoading && (
      <Fragment>
        <Label
          bold
          fontSize={biggestText}
        >
          {amount}
        </Label>
        <Label
          fontSize={normalText}
          color={darkGray}
        >
          {count}
        </Label>
      </Fragment>
    )}
  </Card>
)

TotalAmount.defaultProps = {
  title: 'Volume Total'
}

TotalAmount.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  count: PropTypes.string,
  isLoading: PropTypes.bool
}

export default TotalAmount
