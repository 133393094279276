import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { smallText } from 'src/Styles/settings/Constants'

const Label = styled.label`
  font-size: ${smallText};
  margin-bottom: 0;
  min-width: 3rem;
  text-align: ${({ align }) => align};

  ${({ color }) => color && css`
    color: ${color};
  `}
`

Label.defaultProps = {
  align: 'left'
}

Label.propTypes = {
  align: PropTypes.oneOf(['right', 'left']),
  color: PropTypes.string
}

export default Label
