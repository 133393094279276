import React from 'react'
import Proptypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Can from 'src/Helpers/Permission/Can'
import { toMoney } from 'src/Utils/Utils'

import Icon from 'src/UIComponents/Icon/Icon'
import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'

import AddSellerComponent from 'src/Components/AddSellerComponent/AddSellerComponent'

import { LastCommissionLoader } from './LastCommissionLoader'
import {
  lighterGray,
  darkGray,
  smallMargin,
  bigMargin,
  normalText,
  bigText,
  gray, darkerGray
} from 'src/Styles/settings/Constants'
import { Beacon } from '../../Components/OnboardingHints/Beacon'

const HeaderInfo = (
  {
    title,
    infoResume,
    dateSelector,
    actions,
    customInfo,
    commission,
    commissionRequesting,
    onClickCommissionDetail
  }) => {
  const {
    sellerId,
    isAccountCreationDisabled
  } = useSelector(({ context, settings }) => ({
    isAccountCreationDisabled: settings.items.disableAccountCreation,
    sellerId: context.seller?.id
  }))

  const matchSellerURL = useRouteMatch({
    path: '*/estabelecimentos'
  })

  return (
    <Container>
      <TopInfo>
        <LeftContainer>
          <Title bar big data-test={`title-page-${title}`}>
            <strong>
              {title}
            </strong>
          </Title>
        </LeftContainer>
        <Can do='create' on='Seller'>
          {matchSellerURL && !isAccountCreationDisabled && <AddSellerComponent />}
        </Can>
        {
          !matchSellerURL && !sellerId &&
          <Can I='read' a='Markup'>
            {commission && (
              <CommissionContainer
                className='commision-container'
                onClick={onClickCommissionDetail}
                data-test='markup-btn-info'
              >
                {commissionRequesting && <LastCommissionLoader />}
                {!commissionRequesting && (
                  <>
                    <LabelContainer>
                      <Label bold color={darkerGray} uppercase>Último markup recebido</Label><span> - {commission.date}</span>
                    </LabelContainer>
                    <Label fontSize='1.6rem' bold>{toMoney(commission.amount)}</Label>
                    <Label color={darkGray} fontSize='1.4rem' bold>Ver histórico</Label>
                  </>
                )}
              </CommissionContainer>
            )}
          </Can>
        }
      </TopInfo>
      <Can I='read' a='KPI'>
        <InfoCard>
          <TopInfoCard>
            {dateSelector}
            {actions}
          </TopInfoCard>
          <InfoCardContainer className='kpis'>
            {
              customInfo || (
                <>
                  {infoResume.map((info, index) => (
                    <>
                      <CustomCard
                        disabled={(info.isEmpty && !info.icon) || info.hasNoDetail}
                        onClick={!info.isEmpty || info.icon ? info.onClick : undefined}
                        key={index}
                        data-test={`kpi-card-${info.label || 'info'}`}
                        data-tracking={`kpi-card-${info.label || 'info'}`}
                      >
                        {info.label && (
                          <Label
                            bold
                            color={darkGray}
                            fontSize={normalText}
                            uppercase
                          >
                            {info.label}
                          </Label>
                        )}
                        {info.icon && (
                          <ErrorIcon src={info.icon} />
                        )}
                        {!info.isEmpty
                          ? <Label
                            bold
                            fontSize={bigText}
                          >
                            {info.info}
                          </Label>
                          : <>
                            {info.title && (
                              <Label
                                bold
                                color={gray}
                              >
                                {info.title}
                              </Label>
                            )}
                            <Label
                              color={gray}
                            >
                              {info.info}
                            </Label>
                          </>
                        }
                        {!info.hasNoDetail && <CustomIcon disabled={info.isEmpty || info.hasNoDetail} src='/assets/icons/system/charts.svg' />}
                      </CustomCard>
                      {index === 0 && <Beacon hint='cards-kpis' wrapperProps={{ left: '-10px', top: '20px' }} />}
                    </>
                  ))}
                </>
              )
            }
          </InfoCardContainer>
        </InfoCard>
      </Can>
    </Container>
  )
}

HeaderInfo.defaultProps = {
  infoResume: [],
  onClickCommissionDetail: () => { /* noop */ }
}

HeaderInfo.propTypes = {
  customInfo: Proptypes.any,
  title: Proptypes.string,
  infoResume: Proptypes.array,
  dateSelector: Proptypes.node,
  actions: Proptypes.any,
  onClickCommissionDetail: Proptypes.func,
  commission: Proptypes.object,
  commissionRequesting: Proptypes.bool
}

const Container = styled.div`
  display: block;
  margin-bottom: ${bigMargin};
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
`

const InfoCard = styled.div`
  display: block;
`

const TopInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${lighterGray};
  border-radius: 10px;
  padding: 10px 20px;
  min-height: 110px;
  margin-bottom: 90px;
`

const InfoCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: -170px;
`

const CustomIcon = styled(Icon)`
  position: absolute;
  bottom: 10px;
  right: 10px;

  svg {
    cursor: pointer;
    /* transform: translate(-100%, 100%); */
    /* transition: transform .3s ease-in-out; */
  }
`

const ErrorIcon = styled(ReactSVG)`
  margin-bottom: ${smallMargin};
`

const CustomCard = styled(Card)`
  height: 100px;
  justify-content: center;
  text-align: center;
  margin: 3px 7px 0 7px;

  &:nth-child(2) {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  ${({ disabled }) => disabled && css`
    rect {
      fill: ${lighterGray};
    }
  `}

  ${({ disabled }) => !disabled && css`
    cursor: pointer;

    rect {
      fill: ${darkGray};
      transition: fill .3s ease-in-out;
    }

    &:hover {
      rect {
        fill: ${props => props.theme.color};
      }
    }
  `}
`

const CommissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: end;
  padding: 10px;
  cursor: pointer;
`

const LabelContainer = styled.div`
  font-size: 1.2rem;
`

export default HeaderInfo
