import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { toMoney, getTextPlural } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Card from 'src/UIComponents/Card/Card'
import BarChart from 'src/UIComponents/Charts/BarChart'
import PieChart from 'src/UIComponents/Charts/PieChart'
import PeriodLabel from 'src/UIComponents/Label/PeriodLabel'
import TotalAmount from 'src/UIComponents/KPIDetails/TotalAmount'
import TransactionsByCardBrand from 'src/UIComponents/KPIDetails/TransactionsByCardBrand'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { darkGray, normalText } from 'src/Styles/settings/Constants'

const KPITransactionsSucceeded = ({ period, amount, count, color, barChartData, pieChartData, topHits, isLoading }) => (
  <Fragment>
    {!isLoading && count === 0
      ? (<SidebarContainer emptyState>
        <EmptyState
          iconSrc='/assets/icons/alerts/success.svg'
          title={'Poxa! Nenhuma venda foi aprovada no\nperíodo selecionado'}
        />
      </SidebarContainer>)
      : (<SidebarContainer>
        <Grid.Row auto>
          <DateContainer cols={16}>
            <PeriodLabel period={period} />
          </DateContainer>
        </Grid.Row>
        <Grid.Row auto>
          <TotalAmount
            amount={toMoney(amount)}
            count={`${count} ${getTextPlural('transaction', count)}`}
            isLoading={isLoading}
          />
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='sales-kpi-progression'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Progressão Geral de Vendas
            </Label>
            <BarChart color={color} width='100%' layout='horizontal' height={252} data={barChartData} isLoading={isLoading} />
          </Card>
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='sales-kpi-type'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Vendas por Tipo
            </Label>
            <PieChart color={color} width='100%' height={320} data={pieChartData} isLoading={isLoading} />
          </Card>
        </Grid.Row>
        <Grid.Row auto>
          <Card data-test='sales-kpi-cardBrand'>
            <Label
              bold
              uppercase
              color={darkGray}
              fontSize={normalText}
            >
              Vendas por Bandeira
            </Label>
            <Grid.Row />
            <TransactionsByCardBrand items={topHits} isLoading={isLoading} />
          </Card>
        </Grid.Row>
      </SidebarContainer>)
    }
  </Fragment>
)

KPITransactionsSucceeded.propTypes = {
  amount: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  barChartData: PropTypes.array,
  pieChartData: PropTypes.array,
  topHits: PropTypes.array,
  isLoading: PropTypes.bool,
  period: PropTypes.number
}

export default KPITransactionsSucceeded

const DateContainer = styled(Grid.Col)`
  text-align: center;
  font-size: ${normalText};
`
